<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->

        <!--end::Search-->
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <router-link
            :to="{ name: 'optimize-job-date-selection' }"
            class="btn btn-primary"
          >
            New Optimization Plan
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <v-client-table
        :columns="columns"
        v-model="optimizeList"
        :options="options"
      >
        <template slot="id" slot-scope="props">
          <span class="text-hover-primary"> {{ props.row.id }}</span>
          <job-dropdown
            class="float-right"
            :id="props.row.id"
            :job="props.row"
          />
        </template>

        <template slot="createdOn" slot-scope="props">
          {{ props.row.created_on }}
        </template>

        <div slot="date" slot-scope="props">
          {{ props.row.date }}
        </div>

        <div slot="actions" slot-scope="props">
          <router-link
            v-if="props.row.status == 'success'"
            :to="{ name: 'optimize-job', params: { id: props.row.id } }"
            class="btn btn-sm btn-primary"
          >
            View
          </router-link>
        </div>
      </v-client-table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import { getOptimizeTrips } from "@/api/optimize.api";

export default {
  setup() {
    const optimizeList = ref([]);

    onMounted(async () => {
      await loadData();
    });

    const loadData = async () => {
      const { data } = await getOptimizeTrips();
      optimizeList.value = data;
    };

    const columns = ["id", "createdOn", "date", "status", "actions"];
    const options = {
      headings: {
        id: "Id",
        createdOn: "Created",
        date: "Date",
        status: "Status",
        actions: "Actions",
      },
      skin: "b-table table table-bordered",
      tabbable: false,
      filterable: false,
      sortable: [],
    };

    return {
      columns,
      options,

      optimizeList,
    };
  },
};
</script>
