import ApiService from "@/core/services/api.service";

const OPTIMIZE_END_POINT = "/optimize";

const getOptimizeTrips = () => ApiService.get(`${OPTIMIZE_END_POINT}`);

const getOptimizationSolution = (id) =>
  ApiService.get(`${OPTIMIZE_END_POINT}/${id}`);

const postAsyncOptimizeTrips = (date) =>
  ApiService.post(`${OPTIMIZE_END_POINT}?date=${date}`);

const assignDriverToTrips = (payload) =>
  ApiService.post(`${OPTIMIZE_END_POINT}/optimize-assign-driver`, payload);

export {
  getOptimizeTrips,
  getOptimizationSolution,
  postAsyncOptimizeTrips,
  assignDriverToTrips,
};
